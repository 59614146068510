const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    serverIpData: [],
    versionsData:[]
}

const configurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_CONFIGURATION':
            return { ...state }
        case 'UPDATE_CONFIGURATION':
            return { ...state }
        case 'GET_DATA_CONFIGURATION_LIST':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }


        case 'GET_SERVER_IP_DATA':
            return {
                ...state,
                serverIpData: action.data,

            }
        case 'GET_SKIP_FORCE_VERSION':
            return {
                ...state,
                versionsData: action.data,

            }
        case 'UPDATE_SKIP_FORCE_VERSION':
                return { ...state }
        default:
            return { ...state }

    }
}
export default configurationReducer
