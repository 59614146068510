import { combineReducers } from "redux";
import AuthUserReducer from "./AuthUserReducer";
import AppSettingsReducer from "./AppSettingsReducer";
import UserSettingsReducer from "./UserSettingsReducer";
import MenuListReducer from "./MenuListReducer";
import AuthReducer from "./auth";
import FormInfoReducer from "./FormInfoReducer";
import configurationReducer from "./master/ConfigurationMasterReducer";

import UserMasterReducer from "./master/UserMasterReducer";
import galleryReducer from "./GalleryReducer"
import galleryCategoryReducer from "./GalleryCategoryReducer";
import serverSettingsReducer from "./ServerSettingsReducer";

const reducers = combineReducers({
  authUser: AuthUserReducer,
  settings: AppSettingsReducer,
  UserSettingsReducer: UserSettingsReducer,
  menuListReducer: MenuListReducer,
  authReducer: AuthReducer,
  formInfoReducer: FormInfoReducer,
  userMasterReducer: UserMasterReducer,
  configurationReducer: configurationReducer,
  galleryReducer: galleryReducer,
  galleryCategoryReducer: galleryCategoryReducer,
  serverSettingsReducer: serverSettingsReducer,
});

export default reducers;
